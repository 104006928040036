<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-8">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <h3 class="mb-0">
            {{ lista_campos != "" ? "Editar |" : "Criar |" }} Ponto de Venda
          </h3>
        </div>
        <div class="card-body table-responsive">
          <form action="" class="text-uppercase">
            <div class="form-group row text-uppercase">
              <div class="col-md-12">
                <label class="col-md-12">nome:*</label>
                <input
                  required
                  type="text"
                  class="form-control"
                  v-model="form.nome"
                  placeholder="Digite o nome do pdv..."
                />
              </div>
              <div class="col-md-12 pt-2">
                <label class="col-md-12">endereco:*</label>
                <input
                  required
                  type="text"
                  class="form-control"
                  v-model="endereco"
                  placeholder="Digite o nome..."
                />
              </div>
              <div v-show="false" class="col-md-3 pt-2">
                <label class="col-md-12">numero:*</label>
                <input
                disabled
                  required
                  type="text"
                  class="form-control"
                  v-model="form.numero"
                  placeholder="Digite o nome..."
                />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-4">
                <label class="col-md-12">Pais:*</label>
                <treeselect
                  required=""
                  :multiple="false"
                  :options="[
                    { id: 1, label: 'Brasil' },
                    { id: 28, label: 'Paraguai' },
                  ]"
                  placeholder="Selecione o Pais..."
                  v-model="pais_id"
                  @input="onInput2"
                />
              </div>
              <div class="col-md-4" v-show="pais_id != 28">
                <label class="col-md-12">Estado:*</label>
                <treeselect
                  :multiple="false"
                  :options="lista_estados"
                  placeholder="Selecione o Estado..."
                  v-model="estados_id"
                  @input="onInput"
                />
              </div>
              <div class="col-md-4">
                <label class="col-md-12">Cidade:*</label>
                <treeselect
                  :multiple="false"
                  :options="lista_cidades"
                  placeholder="Selecione a Cidade..."
                  v-model="form.cidade_id"
                  @input="onInput3"
                />
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-6">
                <label class="col-md-12">Responsavel:*</label>
                <input
                  required
                  type="text"
                  class="form-control"
                  v-model="form.responsavel"
                  placeholder="Digite o responsavel pelo local..."
                />
              </div>
              <div class="col-md-6">
                <label class="col-md-12">telefone:*</label>
                <input
                  required
                  type="text"
                  class="form-control"
                  v-model="tel1"
                  placeholder="Digite o telefone..."
                  v-mask="valmask"
                />
              </div>

              <div class="col-md-9 ">
                <label class="col-md-12">Logo do PDV:*</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="form.logo"
                  placeholder="colocar link logo tamanho 98x98"
                />
                
              </div>
              <div class="col-md-3 font-weight">
                Logo
                <v-img
                  max-height="98"
                  max-width="98"
                  :src="lista_campos.logo"
                ></v-img>
              </div>
              
            </div>
            <div class="row form-group ">
              <div class="col-md-3">
                <label class="col-md-12">abertura</label>

                <b-form-timepicker
                  v-model="form.hora_abe"
                  locale="en"
                  placeholder="abertura"
                ></b-form-timepicker>
              </div>

              <div class="col-md-3">
                <label class="col-md-12">  fechamento</label>

                <b-form-timepicker
                  v-model="form.hora_fec"
                  locale="en"
                  placeholder="fechamento"
                ></b-form-timepicker>
              </div>

              <div class="col-md-6 pt-1" style="font-size: small;" >
                <div class="md-radio-inline">
                  <label>Dias da semana:*</label>
                  <div class=" " >
                    <b-form-checkbox-group
                      :options="dias_semana"
                      v-model="form.ds"
                    >
                    
                    </b-form-checkbox-group>
                  </div>
                </div>
              </div>
              <div class="col-md-12 pt-4">
                <label class="col-md-12">Descrição:*</label>
                <input
                  required
                  type="text"
                  class="form-control"
                  v-model="form.descricao"
                  placeholder="Digite o descrição..."
                />
              </div>
              <div class="col-md-12 pt-4 ">
                <label class="col-md-12">Link mapa:*</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="form.link"
                  placeholder="colocar link mapa"
                />
                
              </div>
            </div>

            <div class="row form-group col-md-12">
              <div class="col-md-10 pull-left">
                <div class="md-radio-inline text-uppercase">
                  <label>Status:*</label>
                  <div class="space d-flex">
                    <b-form-radio
                      :inline="true"
                      value="2"
                      v-model="form.status"
                    >
                      inativo
                    </b-form-radio>
                    <b-form-radio
                      :inline="true"
                      value="1"
                      v-model="form.status"
                    >
                      ativo
                    </b-form-radio>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <label for="valor_unitario" class="col-md-12"
                >Mapa do evento
              </label>

              <iframe
                width="100%"
                height="450"
                style="border: 0"
                loading="lazy"
                allowfullscreen
                referrerpolicy="no-referrer-when-downgrade"
                :src="
                  'https://www.google.com/maps/embed/v1/place?key=AIzaSyCVLwaGKu4EOleaGwMAfncO1bB2YM23ZGs&q='+localizacao"
              >
              </iframe>
            </div>
            <div class="form-group row">
              <div class="col-md-12 text-center">
                <button
                  @click.prevent="
                    confirm(lista_campos == '' ? 'cria' : 'edita')
                  "
                  class="btn btn-primary"
                  :disabled="verif"
                >
                  Salvar
                  <b-spinner
                    v-show="verif"
                    small
                    variant="dark"
                    type="grow"
                    label="Spinning"
                  ></b-spinner>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fireAlert } from "@/core/mixins/alertMessageMixin";

export default {
  mixins: [fireAlert],
  data() {
    return {
      form: {
        nome: null,
        endereco: "",
        numero: null,
        descricao: null,
        cidade_id: null,
     ds:[],

        responsavel: null,
        telefone: null,
        status: 2,
        logo:null
        
      },
      nome_cidade: null,
      pais_id: null,
      estados_id: null,
      verif: false,
      localizacao: null,
      dias_semana: [
      { text: "dom", value: "dom" },
        { text: "seg", value: "seg" },
        { text: "ter", value: "ter" },
        { text: "qua", value: "qua" },
        { text: "qui", value: "qui" },
        { text: "sex", value: "sex" },
        { text: "sab", value: "sab" },
      ],
      tel1:null,
      endereco:null,

      valmask:""
    };
  },
  created() {
    this.preenxerCampos();
  },
  watch:{
    tel1(){
      
      this.valmask =this.tel1.length == 15 ? '+### ### ######':'+##(##)#########'
    this.form.telefone = this.tel1.replace(/[^0-9]/g, "");
    },
    endereco(){
     
      this.form.numero =   this.endereco.replace(/[^0-9]/g,'')

      this.form.endereco  =   this.endereco.replace(/[\d]/g,'')

    }
  },
  computed: {
    lista_campos() {
      return this.$store.state.configEmpresa.lista_campos;
    },
    mensagem_alert() {
      return this.$store.state.pdv.mensagem_alert;
    },
    lista_estados() {
      return this.$store.state.endereco.lista_estados;
    },
    lista_cidades() {
      return this.$store.state.endereco.lista_cidades;
    },
  },
  methods: {
    async confirm(tipo) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação  ` + tipo + ` um Pdv no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo == "cria") this.create();
            if (tipo == "edita") this.update();
          }
        },
      });
    },
    async create() {
      this.verif = true;
      await this.$store.dispatch("pdv/create_pdv", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
        routeName: "pdv",
      });
    },
    async update() {
      this.verif = true;
      await this.$store.dispatch("pdv/update_pdv", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
        routeName: "pdv",
      });
    },

    onInput(value) {
      this.$store.dispatch("endereco/listar_cidades", value);
      this.form.cidade_id = null;
    },
    onInput2(value) {
      if (value != 28) this.$store.dispatch("endereco/listar_estados", value);
      else this.$store.dispatch("endereco/listar_cidades", value);
    },
    onInput3(value) {
      for (let index = 0; index < this.lista_cidades.length; index++) {
        console.log(this.lista_cidades[index]["id"]);
        console.log(value);
        if (this.lista_cidades[index]["id"] == value) {
          this.nome_cidade = this.lista_cidades[index]["label"];
        }
      }
      if (this.form.endereco != "") {
 
        this.localizacao = this.nome_cidade+' '+ this.form.endereco+' '+this.form.numero;
      } else {
        this.localizacao = this.nome_cidade ;
      }
   this.localizacao = this.localizacao.replace(/ /g,'+').normalize('NFD').replace(/[\u0300-\u036f]/g, "")
      console.log(this.localizacao);
    },

    preenxerCampos() {
      if (this.lista_campos != "")
        this.form = {
          id: this.lista_campos.id,
          // endereco: this.lista_campos.endereco ,
          nome: this.lista_campos.nome,


          // numero: this.lista_campos.numero,
          descricao: this.lista_campos.descricao,
          ds : this.lista_campos.ds,
          hora_abe: this.lista_campos.hora_abe,
          hora_fec: this.lista_campos.hora_fec,

           responsavel: this.lista_campos.responsavel,
          telefone : this.lista_campos.telefone,
          logo : this.lista_campos.logo,
          link : this.lista_campos.link,

          status : this.lista_campos.status,
          cidade_id : this.lista_campos.cidade_id,





        };

        this.pais_id = this.lista_campos.pais_id
this.tel1 = this.lista_campos.telefone
        this.estados_id = this.lista_campos.estados_id
        this.cidade_id = this.lista_campos.cidade_id

       this.endereco = this.lista_campos.endereco + ' ' + this.lista_campos.numero 
       this.localizacao = this.lista_campos.nome_cidade+'+'+ this.lista_campos.endereco + '+' + this.lista_campos.numero
   this.localizacao = this.localizacao.replace(/ /g,'+').normalize('NFD').replace(/[\u0300-\u036f]/g, "")

       console.log(this.localizacao);

    },
    
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>